import * as React from 'react';
import MaterialTable from "@material-table/core";
import { useFetchApi } from '../utils/UseFetchApi'
import { ClusterLink, clusterHasTrouble } from './ClusterDetails'
import { enqueueSnackbar } from 'notistack';
import DeleteClusterDialog from './DeleteClusterDialog';
import { Skeleton } from '@mui/material';

interface IClusterJobsCountByState {
    active: number;
    completed: number;
}

interface IClusterJobStatistics extends IClusterJobsCountByState {
    clusterName: string;
    clusterLocation: string;
}

export enum ClusterState {
    Steady = "Steady",
    Resizing = "Resizing",
    Stopping = "Stopping"
}
interface INodeStatistics {
    idle: number;
    running: number;
    starting: number;
    rebooting: number;
    start_task: number;
    start_task_failed: number;
    leaving: number;
    unusable: number;
    preempted: number;
    other: number;
}

interface IDeleteConfirmationState {
    isOpen: boolean;
    cluster?: IClusterInfo;
    isDeleteAllDisabled: boolean;
    duplicateClusters?: IClusterInfo[];
}

export interface IClusterInfo {
    name: string;
    dedicatedNodes: number;
    lowPriorityNodes: number;
    state: ClusterState;
    location: string;
    jobsCountByState: IClusterJobsCountByState;
    nodeStatistics: INodeStatistics;
}

export default function Clusters() {
    const clustersApi = useFetchApi<IClusterInfo[]>(window.location.origin + "/api/v2/clusters/minimal");
    const clustersJobStatisticsApi = useFetchApi<IClusterJobStatistics[]>(window.location.origin + "/api/v2/clusters/jobstatistics");
    const [currentDisplayedRowsCount, setCurrentDisplayedRowsCount] = React.useState<number>(0);
    const [tableData, setTableData] = React.useState<IClusterInfo[]>([]);
    const [searchText, setSearchText] = React.useState<string>("");
    const fetchApi = useFetchApi<string>();
    const tableRef = React.useRef<MaterialTable<IClusterInfo[]>>();
    const [deleteConfirmation, setDeleteConfirmation] = React.useState<IDeleteConfirmationState>({
        isOpen: false,
        isDeleteAllDisabled: false,
        duplicateClusters: [],
    });

    enum JobStatisticsColumnName {
        Active,
        Completed
    }

    React.useEffect(() => {
        refreshTableData();
    }, []);

    React.useEffect(() => {
        if (!clustersApi.isFetching && clustersJobStatisticsApi.isFetching) {
            //we need to enable the table. Let the statistics be displayed later.
            setTableData(clustersApi.data ?? []);
        }
        if (!clustersApi.isFetching && !clustersJobStatisticsApi.isFetching) {
            //merge the data.
            var data = clustersApi.data;

            data = data?.map(cluster => {
                cluster = {
                    ...cluster,
                    jobsCountByState: {
                        active: getJobStatisticsDataForTableRow(cluster, JobStatisticsColumnName.Active),
                        completed: getJobStatisticsDataForTableRow(cluster, JobStatisticsColumnName.Completed)
                    }
                };
                return cluster;
            })
            setTableData(data ?? []);
        }
    }, [clustersApi.isFetching, clustersJobStatisticsApi.isFetching])

    React.useEffect(() => {
        handleRowsCountChange();
    }, [(tableRef.current as any)?.state, searchText])  //If any other functionality will change the current rows displayed, the state has to be added here in dependency array.

    function handleRowsCountChange() {
        setCurrentDisplayedRowsCount(getCurrentDisplayedRowsCount())
    }

    function handleDeleteConfirmation(cluster: IClusterInfo) {
        const duplicateClusters = findDuplicateClusters(clustersApi.data ?? [], cluster);

        const nonResizingDuplicateClusters = duplicateClusters.filter(
            duplicateCluster => duplicateCluster.state !== ClusterState.Resizing
        );

        const filteredDuplicateClusters = nonResizingDuplicateClusters.filter(
            duplicateCluster => duplicateCluster.name !== cluster.name || duplicateCluster.location !== cluster.location
        );

        if (nonResizingDuplicateClusters.length > 0) {
            setDeleteConfirmation({ isOpen: true, cluster, isDeleteAllDisabled: false, duplicateClusters: filteredDuplicateClusters });
        } else {
            setDeleteConfirmation({ isOpen: true, cluster, isDeleteAllDisabled: true });
        }
    }

    async function handleDeletion(clusters: IClusterInfo[]) {
        try {
            // filtering out clusters in resizing state
            const clustersToDelete = clusters.filter(cluster => cluster.state !== ClusterState.Resizing);

            const deletionTask = fetchApi.run(window.location.origin + `/api/v2/clusters/locations/delete`, {
                method: 'DELETE',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(clustersToDelete.map(cluster => ({
                    location: cluster.location,
                    clusterId: cluster.name
                })))
            });

            setDeleteConfirmation({ isOpen: false, isDeleteAllDisabled: false });
            const response = await deletionTask;

            if (response) {
                refreshTableData(); // refreshes clusters data
                const variant = response.toLowerCase().includes('failed') ? 'info' : 'success';
                enqueueSnackbar(response, { variant });
            }
        } catch (error) {
            enqueueSnackbar(`Error deleting cluster: ${(error instanceof Error) ? error.message : 'Unknown error'}`, { variant: "error" });
        }
    }

    async function handleDeleteConfirmed(cluster: IClusterInfo) {
        await handleDeletion([cluster]);
    }

    async function handleDeleteAllConfirmed() {
        const { cluster, duplicateClusters } = deleteConfirmation;

        const clustersToDelete = [cluster!];
        if (duplicateClusters && duplicateClusters.length) {
            clustersToDelete.push(...duplicateClusters);
        }

        await handleDeletion(clustersToDelete);
    }

    function handleCloseConfirmation() {
        setDeleteConfirmation({ isOpen: false, isDeleteAllDisabled: false });
    }

    const handleSearchChange = (currentSearchText: string) => {
        setSearchText(currentSearchText);
    }

    function findDuplicateClusters(clusters: IClusterInfo[], clusterToDelete: IClusterInfo): IClusterInfo[] {
        return clusters.filter(cluster => cluster.name === clusterToDelete.name && cluster.location !== clusterToDelete.location);
    }

    function getTrouble(cluster: IClusterInfo) {
        if (clusterHasTrouble(cluster))
            return "⚠️"
        return "✔️"
    }

    function getColumnDataLoadingComponent() {
        return <Skeleton variant="rectangular" width="25%" height="1rem" />;
    }

    function isJobStatisticsLoading() {
        return clustersJobStatisticsApi.isFetching;
    }

    function getJobStatisticsDataForTableRow(tableRowData: IClusterInfo, column: JobStatisticsColumnName): number {
        switch (column) {
            case JobStatisticsColumnName.Active:
                return clustersJobStatisticsApi.data?.find(s => s.clusterLocation == tableRowData.location && s.clusterName == tableRowData.name)?.active ?? 0;
            case JobStatisticsColumnName.Completed:
                return clustersJobStatisticsApi.data?.find(s => s.clusterLocation == tableRowData.location && s.clusterName == tableRowData.name)?.completed ?? 0;
            default:
                return 0;
        }
    }

    function refreshTableData() {
        if (!clustersApi.isFetching)
            clustersApi.run();
        if (!clustersJobStatisticsApi.isFetching)
            clustersJobStatisticsApi.run();
    }

    function getCurrentDisplayedRowsCount() {
        // This is obtained by checking the actual current ref object. groupedDataLength contains the actual rows currently being displayed.
        const currentRows = (tableRef?.current as any)?.dataManager?.groupedDataLength;
        return currentRows;
    }

    function isTableLoading(): boolean {
        return clustersApi.isFetching || fetchApi.isFetching;
    }

    return (
        <div style={{ padding: '16px' }}>
            <MaterialTable<IClusterInfo>
                title={"Clusters (" + currentDisplayedRowsCount + ")"}
                tableRef={tableRef}
                onSearchChange={handleSearchChange}
                isLoading={isTableLoading()}
                columns={[
                    { title: "Name", field: "name", sorting: true, render: rowData => <ClusterLink cluster={rowData} /> },
                    { title: "Location", field: "location", grouping: true, defaultGroupOrder: 0 },
                    {
                        title: <div>Total nodes<br />(dedicated/low-priority)</div>, minWidth: 300,
                        render: rowData =>
                            <div>
                                {rowData.dedicatedNodes + rowData.lowPriorityNodes} ({rowData.dedicatedNodes + '/' + rowData.lowPriorityNodes})
                            </div>,
                        customSort: (data1, data2, type, sortDirection) => {
                            //will sort based on total nodes.
                            return ((data1.dedicatedNodes + data1.lowPriorityNodes) - (data2.dedicatedNodes + data2.lowPriorityNodes))
                        },
                        customFilterAndSearch: (filter, rowData, columnDef) => {
                            var data = rowData.dedicatedNodes + rowData.lowPriorityNodes;
                            return data.toString().includes(filter.toString());
                        }
                    },
                    {
                        title: "Jobs active", sorting: !isJobStatisticsLoading(), searchable: !isJobStatisticsLoading(), field: 'jobsCountByState.active',
                        draggable: !isJobStatisticsLoading(),   // the column position cannot be changed by dragging/dropping this or any other column.
                        render: rowData => rowData.jobsCountByState === undefined   //the job statistics endpoint call has not yet completed.
                            ? getColumnDataLoadingComponent()
                            : rowData.jobsCountByState?.active ?? 0
                    },
                    {
                        title: "Jobs completed", sorting: !isJobStatisticsLoading(), searchable: !isJobStatisticsLoading(), field: 'jobsCountByState.completed',
                        draggable: !isJobStatisticsLoading(),   // the column position cannot be changed by dragging/dropping this or any other column.
                        render: rowData => rowData.jobsCountByState === undefined
                            ? getColumnDataLoadingComponent()
                            : rowData.jobsCountByState?.completed ?? 0

                    },
                    { title: "State", field: "state" },
                    {
                        title: "Trouble?", field: "trouble",
                        render: rowData => <div>{getTrouble(rowData)}</div>,
                        sorting: false
                    }
                ]}
                data={tableData ?? []}
                actions={[
                    {
                        tooltip: 'Refresh',
                        icon: 'refresh',
                        isFreeAction: true,
                        onClick: () => refreshTableData(),
                    },
                    rowData => ({
                        tooltip: 'Delete cluster',
                        icon: 'delete',
                        disabled: rowData.state.toString().toLowerCase() !== ClusterState[ClusterState.Steady].toLowerCase(),
                        onClick: (_event, rowData) => handleDeleteConfirmation(rowData as IClusterInfo),
                    })
                ]}
                options={{
                    actionsColumnIndex: -1,
                    search: true,
                    sorting: true,
                    paging: false,
                    showGroupingCount: true,
                    headerStyle: { backgroundColor: '#9BA5AE', color: '#000000' },
                    padding: 'dense'
                }}
            />

            {
                deleteConfirmation.isOpen &&
                <DeleteClusterDialog
                    isOpen={deleteConfirmation.isOpen}
                    cluster={deleteConfirmation.cluster}
                    isDeleteAllDisabled={deleteConfirmation.isDeleteAllDisabled}
                    duplicateClusters={deleteConfirmation.duplicateClusters}
                    onClose={handleCloseConfirmation}
                    onDeleteConfirmed={() => handleDeleteConfirmed(deleteConfirmation.cluster as IClusterInfo)}
                    onDeleteAllConfirmed={handleDeleteAllConfirmed}
                />
            }

        </div>
    );
}
